const makeStringToArray = (
  input: string | string[] | undefined
): string[] | null => {
  if (!input) return null
  if (typeof input === 'string')
    return input.split(',')?.map((d: any) => `${d}`.trim())

  return input
}
export default makeStringToArray
