import MaterialTable from '@material-table/core'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { getUserRequests } from '@peakconcepts/leeeds-customer-portal-shared'

const RequestsTable = ({ id }: { id: string }) => {
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const { data: newData, columns: newColumns } = await getUserRequests(
        id,
        firebase.app()
      )
      setColumns(newColumns)
      setData(newData)
      setLoading(false)
    }
    getData()
  }, [id])
  return (
    <MaterialTable
      isLoading={loading}
      title="Anfragen"
      columns={columns}
      detailPanel={[
        {
          tooltip: 'Alle Felder',
          render: (rowData) => {
            return (
              <div>
                <pre>
                  {JSON.stringify(
                    { ...rowData, tableData: undefined },
                    null,
                    2
                  )}
                </pre>
              </div>
            )
          }
        }
      ]}
      data={data}
      options={{ pageSize: 20, columnsButton: true }}
      style={{ width: '100%' }}
    />
  )
}

export default RequestsTable
