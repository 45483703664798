/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FileBrowser, { Icons } from 'react-keyed-file-browser'
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css'
import firebase from 'firebase'
import { DateTime } from 'luxon'
import { useNotify } from 'ra-core'
import { downloadFirebaseURL } from '@peakconcepts/leeeds-customer-portal-shared'

const Container = styled.div`
  height: 70vh;
`
const InnerContainer = styled.div`
  height: 70vh;
`

interface BaseFileInterface {
  key: string
  modified: number
  size: number
}
interface FileInterface extends BaseFileInterface {
  ref: firebase.storage.Reference
  metadata: firebase.storage.FullMetadata
}
const FileManager = ({ id }: { id: string }) => {
  const notify = useNotify()
  const [baseFiles, setBaseFiles] = useState<BaseFileInterface[]>([])
  const [files, setFiles] = useState<FileInterface[]>([])

  const getAllFiles = async () => {
    const items = (await firebase.storage().ref(`documents/${id}`).listAll())
      .items
    const mapped: FileInterface[] = []
    for (const i of items) {
      const metadata = await i.getMetadata()
      mapped.push({
        metadata,
        key: i.name,
        ref: i,
        size: metadata.size,
        modified: DateTime.fromISO(metadata.updated).toMillis()
      })
    }
    mapped.sort((a, b) => b.modified - a.modified)
    const base = mapped.map((m) => ({
      key: m.key,
      modified: m.modified,
      size: m.size
    }))

    setFiles(mapped)
    setBaseFiles(base)
  }

  useEffect(() => {
    if (id) getAllFiles()
  }, [id])

  const handleCreateFiles = async (uploadFiles: File[]) => {
    for (const file of uploadFiles) {
      if (files.some((f) => f.key === file.name)) {
        notify(
          `Datei ${file.name} existiert bereits, bitte umbenennen`,
          'error',
          undefined,
          false,
          3000
        )
        continue
      }
      await firebase.storage().ref(`documents/${id}/${file.name}`).put(file)
    }
    await getAllFiles()
    return true
  }

  const handleDownloadFiles = async (keys: string[]) => {
    for (const key of keys) {
      const file = files.find((f) => f.key === key)
      const url = await file?.ref.getDownloadURL()
      downloadFirebaseURL(url, key)
    }
  }

  const handleDeleteFile = async (keys: string[]) => {
    for (const key of keys) {
      const file = files.find((f) => f.key === key)
      await file?.ref.delete()
    }
    await getAllFiles()
  }
  return (
    <Container>
      <InnerContainer>
        <FileBrowser
          files={baseFiles}
          icons={Icons.FontAwesome(4)}
          onCreateFiles={handleCreateFiles}
          onDownloadFile={handleDownloadFiles}
          onDeleteFile={handleDeleteFile}
          sort={(f: any) => f}
          detailRenderer={() => null}
        />
      </InnerContainer>
    </Container>
  )
}

export default FileManager
