import React from 'react'
import { Login, LoginForm } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  main: {
    background: 'linear-gradient(90deg, rgb(0, 31, 86) 0%, rgb(3, 18, 52) 100%)'
  },
  avatar: {
    backgroundImage: 'url(/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 80
  },
  icon: { display: 'none' }
}

const CustomLoginForm = withStyles({
  button: { background: '#F15922' }
})(LoginForm)

const CustomLoginPage = (props: JSX.IntrinsicAttributes) => (
  <Login loginForm={<CustomLoginForm />} {...props} />
)

export default withStyles(styles)(CustomLoginPage)
