/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@material-ui/core'
import firebase from 'firebase'
import { DateTime } from 'luxon'
import { ListBase, useListContext } from 'ra-core'
import React from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  Chart,
  DashboardCard
} from '@peakconcepts/leeeds-customer-portal-shared'

const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  padding: 30px 30px;
  flex-direction: column;
`
const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  ${breakpoints.down('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
  ${breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0px;
  }
`
const InnerDashboard = () => {
  const { data } = useListContext()
  const totalRequests = data?.counter?.totalRequests || 0
  const requestList: number[] = data?.counter?.requestList || []
  const fullRequestList: number[] = data?.counter?.fullRequestList || []

  const startOfDay = DateTime.now().startOf('day').toMillis()
  const startOfDay7DaysAgo = DateTime.now()
    .startOf('day')
    .minus({ days: 7 })
    .toMillis()
  const startOfDay30DaysAgo = DateTime.now()
    .startOf('day')
    .minus({ days: 30 })
    .toMillis()

  return (
    <Container>
      <CardsContainer>
        <DashboardCard
          title={requestList.filter((r) => r > startOfDay).length}
          description="Anfragen heute"
          type="today"
        />
        <DashboardCard
          title={requestList.filter((r) => r > startOfDay7DaysAgo).length}
          description="Anfragen letzte 7 Tage"
          type="last7days"
        />

        <DashboardCard
          title={requestList.filter((r) => r > startOfDay30DaysAgo).length}
          description="Anfragen letze 30 Tage"
          type="last30days"
        />

        <DashboardCard
          title={totalRequests}
          description="Anfragen insgesamt"
          type="alltime"
        />
      </CardsContainer>

      <Chart timestampList={fullRequestList} />
    </Container>
  )
}

const Dashboard = (props: any) => {
  const resetAllMetadataFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('resetAllMetadata')

  const resetMetadata = async (values: any) => {
    const response = await resetAllMetadataFunction()
    console.log(`response`, response)
  }
  return (
    <ListBase {...props}>
      <InnerDashboard />
      <Button variant="outlined" color="primary" onClick={resetMetadata}>
        Alle Metadaten zurücksetzen (Teuer!)
      </Button>
    </ListBase>
  )
}

export default Dashboard
