import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import './css/reset.css'
import './css/default.css'

import {
  FirebaseAuthProvider,
  FirebaseDataProvider
} from 'react-admin-firebase'
import firebase from 'firebase'
import { UserCreate, UserEdit, UserList, UserShow } from './resources/Users'
import { createMuiTheme } from '@material-ui/core/styles'
import germanMessages from 'ra-language-german'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import PeopleIcon from '@material-ui/icons/People'
import TimelineIcon from '@material-ui/icons/Timeline'
import LoginPage from './screens/Login'
import Dashboard from './resources/Dashboard'
import Home from '@material-ui/icons/Home'
import {
  ChangesCreate,
  ChangesEdit,
  ChangesList
} from './resources/ChangeProtocol'

// import { EditGuesser } from 'react-admin'

const i18nProvider = polyglotI18nProvider(() => germanMessages, 'de')

// @ts-ignore
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat'
  },
  palette: {
    primary: {
      light: 'rgb(255, 192, 0)',
      main: 'rgb(255, 192, 0)',
      dark: 'rgb(255, 192, 0)',
      contrastText: '#fff'
    },
    secondary: {
      light: 'rgb(0, 69, 103)',
      main: 'rgb(0, 69, 103)',
      dark: 'rgb(0, 69, 103)',
      contrastText: 'white'
    }
  }
})

const App = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SERNDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  }
  const firebaseApp = !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app()

  const dataProvider = FirebaseDataProvider(firebaseConfig, { logging: false })
  const authProvider = FirebaseAuthProvider(firebaseConfig, {
    logging: false,
    app: firebaseApp,
    persistence: 'local',
    dontAddIdFieldToDoc: false,
    lazyLoading: {
      enabled: true
    },
    firestoreCostsLogger: {
      enabled: true
    }
  })
  const permissionAuthProvider = {
    ...authProvider,
    login: async (params: any) => {
      const user = await authProvider.login(params)
      // @ts-ignore
      const claims = await authProvider.getPermissions()
      const isAdmin = claims.admin
      if (isAdmin) {
        return user
      }
      console.log('logging out')
      // @ts-ignore
      await authProvider.logout()
      throw new Error('Login error, invalid permissions')
    },
    getIdentity: async () => {
      return true
    }
  }
  return (
    <Admin
      theme={theme}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      authProvider={permissionAuthProvider}
      locale="de"
      i18nProvider={i18nProvider}>
      <Resource
        name="metadata"
        options={{ label: 'Dashboard' }}
        list={Dashboard}
        icon={Home}
      />
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit /* EditGuesser */}
        create={UserCreate}
        show={UserShow}
        options={{ label: 'Nutzer' }}
        icon={PeopleIcon}
      />
      <Resource
        name="change_protocol"
        list={ChangesList}
        edit={ChangesEdit}
        create={ChangesCreate}
        options={{ label: 'Änderungsprotokoll' }}
        icon={TimelineIcon}
      />
    </Admin>
  )
}

export default App
