import { User } from '@peakconcepts/leeeds-customer-portal-interfaces'
import firebase from 'firebase'
import { CompactForm, CompactShowLayout, RaBox } from 'ra-compact-ui'
import * as React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  email,
  EmailField,
  Filter,
  FunctionField,
  List,
  required,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useRedirect
} from 'react-admin'
import FileManager from '../components/FileManager'
import RequestsTable from '../components/RequestsTable'
import makeStringToArray from '../helpers/makeStringToArray'
import UserDashboard from './UserDashboard'

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Nach Email suchen" source="email" alwaysOn />
  </Filter>
)

export const UserList = (props: any) => (
  <List
    {...props}
    title="Nutzer"
    filters={<UserFilter />}
    exporter={false}
    bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="firstName" label="Vorname" />
      <TextField source="lastName" label="Nachname" />
      <TextField source="phone" label="Telefon" />
      <TextField source="email" label="Email" />
      <BooleanField source="properties.noAccess" label="Gesperrt?" />
      <BooleanField source="properties.pipelineEnabled" label="Pipeline an" />
      <EditButton label="Bearbeiten" />
    </Datagrid>
  </List>
)

export const UserEdit = (props: any) => {
  const transform = (data: User) => {
    return {
      ...data,
      domains: makeStringToArray(data.domains),
      config: {
        ...data.config,
        notifications: {
          emails:
            data.config?.notifications === 'skipped'
              ? null
              : makeStringToArray(data.config?.notifications?.emails)
        }
      }
    }
  }
  return (
    <Edit {...props} title="Nutzer bearbeiten" transform={transform}>
      <CompactForm layoutComponents={[RaBox]}>
        <RaBox
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <RaBox display="flex" flex="0 0 50%" justifyContent="space-between">
            <BooleanInput source="properties.noAccess" label="Gesperrt?" />
            <BooleanInput
              source="properties.pipelineEnabled"
              label="Pipeline an"
            />
          </RaBox>
          <TextInput disabled source="id" label="ID" />
        </RaBox>
        <RaBox display="flex" justifyContent="space-between">
          <TextInput disabled source="email" />
          <TextInput
            source="config.notifications.emails"
            label="Benachrichtigungsadressen (Kommagetrennt)"
            className="largerInput"
          />
        </RaBox>
        <RaBox display="flex" justifyContent="space-between">
          <TextInput source="firstName" label="Vorname" />
          <TextInput
            source="config.hubspot.apiKey"
            label="Hubspot API-Key"
            className="largerInput"
          />
        </RaBox>
        <RaBox display="flex" justifyContent="space-between">
          <TextInput source="lastName" label="Nachname" />
          <TextInput
            source="domains"
            label="Domains (Kommagetrennt, ohne www., z.B. 'leeeds.com')"
            className="largerInput"
          />
        </RaBox>
        <RaBox display="flex">
          <TextInput source="phone" label="Telefonnummer" />
        </RaBox>
        <RaBox display="flex" justifyContent="space-between">
          <DateInput disabled source="lastupdate" label="Letztes Update" />
          <TextInput disabled source="updatedby" label="Zuletzt bearbeitet" />
        </RaBox>
      </CompactForm>
    </Edit>
  )
}
export const UserShow = (props: any) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Nutzerdaten">
          <CompactShowLayout layoutComponents={[RaBox]}>
            <RaBox display="flex">
              <RaBox display="flex" flex="0 0 25%">
                <BooleanField source="properties.noAccess" label="Gesperrt?" />
              </RaBox>
              <RaBox display="flex" flex="0 0 25%">
                <BooleanField
                  source="properties.pipelineEnabled"
                  label="Pipeline an"
                />
              </RaBox>
              <RaBox display="flex" flex="0 0 50%">
                <TextField source="id" />
              </RaBox>
            </RaBox>
            <RaBox display="flex">
              <RaBox display="flex" flex="0 0 50%">
                <EmailField source="email" label="Email" />
              </RaBox>
              <RaBox display="flex" flex="0 0 50%">
                <TextField
                  source="config.notifications.emails"
                  label="Benachrichtigungsadressen"
                />
              </RaBox>
            </RaBox>
            <RaBox display="flex">
              <RaBox display="flex" flex="0 0 50%">
                <TextField source="firstName" label="Vorname" />
              </RaBox>
              <RaBox display="flex" flex="0 0 50%">
                <TextField
                  source="config.hubspot.apiKey"
                  label="Hubspot API-Key"
                />
              </RaBox>
            </RaBox>

            <RaBox display="flex">
              <RaBox display="flex" flex="0 0 50%">
                <TextField source="lastName" label="Nachname" />
              </RaBox>
              <RaBox display="flex" flex="0 0 50%">
                <TextField source="domains" />
              </RaBox>
            </RaBox>
            <RaBox display="flex">
              <RaBox display="flex" flex="0 0 50%">
                <TextField source="phone" label="Telefon" />
              </RaBox>
            </RaBox>
            <RaBox display="flex">
              <RaBox display="flex" flex="0 0 50%">
                <DateField source="lastupdate" label="Letztes Update" />
              </RaBox>
              <RaBox display="flex" flex="0 0 50%">
                <TextField source="updatedby" label="Zuletzt bearbeitet" />
              </RaBox>
            </RaBox>
          </CompactShowLayout>
        </Tab>
        <Tab label="Nutzer-Dashboard">
          <FunctionField
            className="fullWidthWithChild"
            label="Nutzer-Dashboard"
            render={(record: any) => <UserDashboard id={record.id} />}
          />
        </Tab>
        <Tab label="Dokumente">
          <FunctionField
            className="fullWidthAndHeightWithChild"
            label="Dokumente"
            render={(record: any) => <FileManager id={record.id as string} />}
          />
        </Tab>
        <Tab label="Anfragen">
          <FunctionField
            className="fullWidthWithChild"
            label="Anfragen"
            render={(record: any) => <RequestsTable id={record.id} />}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const UserCreate = (props: any) => {
  const redirect = useRedirect()

  const validateUserCreation = (values: User) => {
    const errors: any = {}
    if (!values.email) {
      errors.email = ['The email is required']
    }
    if (!values.password) {
      errors.password = ['The password is required']
    }
    return errors
  }
  const createUserFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('createNewUser')

  const save = async (values: any) => {
    const { data: uid } = await createUserFunction(values)
    redirect(`${uid}`)
  }
  return (
    <Create {...props} title="Nutzer anlegen" undoable={false}>
      <SimpleForm save={save} validate={validateUserCreation}>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="phone" />
        <TextInput source="position" />
        <TextInput source="email" validate={email()} />
        <TextInput source="password" validate={required()} />
      </SimpleForm>
    </Create>
  )
}
