import { CompactForm, RaBox } from 'ra-compact-ui'
import { required } from 'ra-core'
import RichTextInput from 'ra-input-rich-text'
import {
  DateInput,
  DateTimeInput,
  DeleteButton,
  ReferenceField,
  ReferenceInput,
  RichTextField
} from 'ra-ui-materialui'
import * as React from 'react'
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  List,
  SelectInput,
  TextField,
  useRedirect
} from 'react-admin'

const UserFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput label="Nutzer" source="user_id" reference="users" alwaysOn>
      <SelectInput optionText="email" label="E-Mail" />
    </ReferenceInput>
    <DateInput source="created_at" label="Erstellt" />
  </Filter>
)

export const ChangesList = (props: any) => (
  <List
    {...props}
    sort={{ field: 'created_at', order: 'DESC' }}
    title="Änderungen"
    filters={<UserFilter />}
    exporter={false}
    bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <DateField source="created_at" label="Erstellt" showTime />
      <RichTextField source="description" />
      <ReferenceField label="Nutzer" source="user_id" reference="users">
        <TextField source="email" label="Email" />
      </ReferenceField>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

export const ChangesEdit = (props: any) => {
  return (
    <Edit {...props}>
      <CompactForm layoutComponents={[RaBox]}>
        <RaBox display="flex" justifyContent="space-between">
          <DateTimeInput source="created_at" label="Erstellt" />
          <ReferenceInput source="user_id" reference="users" label="Nutzer">
            <SelectInput
              optionText="email"
              label="Bearbeiten"
              validate={required()}
            />
          </ReferenceInput>
        </RaBox>

        <RichTextInput source="description" fullWidth label="Beschreibung" />
      </CompactForm>
    </Edit>
  )
}

export const ChangesCreate = (props: any) => {
  const redirect = useRedirect()

  const onSuccess = () => {
    redirect('/change_protocol')
  }
  return (
    <Create {...props} onSuccess={onSuccess}>
      <CompactForm layoutComponents={[RaBox]}>
        <RaBox display="flex" justifyContent="space-between">
          <DateTimeInput
            source="created_at"
            initialValue={new Date()}
            label="Erstellt"
          />
          <ReferenceInput source="user_id" reference="users" label="Nutzer">
            <SelectInput
              optionText="email"
              label="E-Mail"
              validate={required()}
            />
          </ReferenceInput>
        </RaBox>

        <RichTextInput source="description" fullWidth label="Beschreibung" />
      </CompactForm>
    </Create>
  )
}
