import { MetaData } from '@peakconcepts/leeeds-customer-portal-interfaces'
import {
  breakpoints,
  Chart,
  DashboardCard
} from '@peakconcepts/leeeds-customer-portal-shared'
import firebase from 'firebase'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  padding: 30px 30px;
  flex-direction: column;
`
const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  ${breakpoints.down('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
  ${breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0px;
  }
`
const UserDashboard = ({ id }: { id: string }) => {
  const [data, setData] = useState<MetaData | undefined>()

  const startOfDay = DateTime.now().startOf('day').toMillis()
  const startOfDay7DaysAgo = DateTime.now()
    .startOf('day')
    .minus({ days: 7 })
    .toMillis()
  const startOfDay30DaysAgo = DateTime.now()
    .startOf('day')
    .minus({ days: 30 })
    .toMillis()

  useEffect(() => {
    const getData = async () => {
      const get = await firebase.app().firestore().doc(`users_meta/${id}`).get()
      const d = (await get.data()) as MetaData
      setData(d)
    }
    getData()
  }, [id])
  return (
    <Container>
      {data && (
        <>
          <CardsContainer>
            <DashboardCard
              title={data.requestList.filter((r) => r > startOfDay).length}
              description="Anfragen heute"
              type="today"
            />
            <DashboardCard
              title={
                data.requestList.filter((r) => r > startOfDay7DaysAgo).length
              }
              description="Anfragen letzte 7 Tage"
              type="last7days"
            />

            <DashboardCard
              title={
                data.requestList.filter((r) => r > startOfDay30DaysAgo).length
              }
              description="Anfragen letze 30 Tage"
              type="last30days"
            />

            <DashboardCard
              title={data.totalRequests}
              description="Anfragen insgesamt"
              type="alltime"
            />
          </CardsContainer>
          <Chart timestampList={data.fullRequestList} />
        </>
      )}
    </Container>
  )
}

export default UserDashboard
